var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('VDataTable',{attrs:{"value":_vm.selected,"show-select":_vm.showSelect,"headers":[
        { text: 'ID', value: 'id', width: 100, sortable: false },
        { text: 'Имя', value: 'values.imyapersonalnye_dannye_rabotnikov.value', sortable: false },
        { text: 'Фамилия', value: 'values.familiyapersonalnye_dannye_rabotnikov.value', sortable: false },
        { text: 'Отчество', value: 'values.otchestvopersonalnye_dannye_rabotnikov.value', sortable: false },
        { text: 'Должность', value: 'values.dolzhnostpersonalnye_dannye_rabotnikov.value', sortable: false },
        { text: 'Компания', value: 'company.name', sortable: false },
        { text: _vm.isReviewer ? 'Исправленные замечания' : 'Неисправленные замечания', value: 'comments', sortable: false } ],"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
        itemsPerPageText: ''
      },"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.count,"mobile-breakpoint":0,"item-class":function (ref) {
            var comments = ref.comments;

            return !!comments ? (_vm.isReviewer ? 'orange lighten-5': 'error lighten-4'): null;
}},on:{"update:options":function($event){_vm.options=$event},"input":function (items) { return _vm.$emit('update:selected', items); },"click:row":function (props) { return _vm.$emit('click:row', props); },"update:page":function (page) { return _vm.$router.push({ query: Object.assign({}, _vm.$route.query, {page: page}) }); },"update:items-per-page":function (size) { return _vm.$router.push({ query: Object.assign({}, _vm.$route.query, {size: size}) }); }},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',[(_vm.showSelect)?_c('td'):_vm._e(),_vm._l((['id', 'imyapersonalnye_dannye_rabotnikov', 'familiyapersonalnye_dannye_rabotnikov', 'otchestvopersonalnye_dannye_rabotnikov', 'dolzhnostpersonalnye_dannye_rabotnikov', 'authorCompanyName']),function(key){return _c('td',{key:key},[_c('VTextField',{attrs:{"dense":"","clearable":"","hide-details":"","value":_vm.$route.query[key]},on:{"input":function (value) {
            var _obj;

            return _vm.$router.push({ query: Object.assign({}, _vm.$route.query, ( _obj = {}, _obj[key] = value, _obj )) });
      }}})],1)}),_c('td')],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }